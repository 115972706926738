<template>
  <row>
    <column-input>
      <form-group-input name="name" :model="formData.name" :min="3" required/>
    </column-input>
    <column-input>
      <form-group-select name="event" title="Event" :model="formData.event" :options="data.events" :disabled="isEdit || !data.events.length" required/>
    </column-input>
    <column-input>
      <form-group-select name="action" title="Action" :model="formData.action" :options="data.actions" :disabled="isEdit || !data.actions.length" required/>
    </column-input>
    <column-input :md="2">
      <form-group-check name="active" :model="formData.active"/>
    </column-input>
  </row>
</template>

<script>
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'

export default {
  name: 'FeedbackFormGeneral',
  components: {
    FormGroupSelect,
    FormGroupCheck,
    FormGroupInput
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true }
  },
  computed: {
    isEdit () { return this.$route.name === 'feedbacks-edit' }
  }
}
</script>
