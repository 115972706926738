<template>
  <div>
    <box-section>Action: Email</box-section>
    <row>
      <column-input>
        <form-group-select name="api" :model="formData.api" :options="data.apis" :disabled="!data.apis.length" required/>
      </column-input>
      <column-input>
        <form-group-select name="sender_categories" :model="formData.sender_categories" :options="data.sender_categories" :disabled="!data.sender_categories.length" multiple required/>
      </column-input>
      <column-input>
        <form-group-select name="domains" :model="formData.domains" :options="data.domains" :disabled="!data.domains.length"  multiple/>
      </column-input>
    </row>
    <row>
      <column-input>
        <form-group-input type="number" name="delay" title="Delay (min)"  :model="formData.delay" :min="0" :max="3600" :step="1"/>
      </column-input>
      <column-input>
        <form-group-select name="offer" :model="formData.offer" :options="data.offers" :disabled="!data.offers.length" />
      </column-input>
    </row>
    <row>
      <column-input>
        <form-group-select name="templates_category" :model="formData.templates_category" :options="data.templateCats" :disabled="!data.templateCats.length" required/>
      </column-input>
      <column-input>
        <form-group-select name="templates" :model="formData.templates" :options="data.templates" :disabled="!data.templates.length" multiple required/>
      </column-input>
    </row>
  </div>
</template>

<script>
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupInput from '@/views/components/form/FormGroupInput'

export default {
  name: 'FeedbackFormActionEmail',
  components: {
    FormGroupSelect,
    FormGroupInput
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true }
  }
}
</script>
