<template>
  <div>
    <box-section>Action: Callback</box-section>
    <row>
      <column-input :md="12" :sm="12">
        <form-group-input name="callback_url" placeholder="https://google.com?param1=value1&param2=value2" :model="formData.callback_url"/>
      </column-input>
    </row>
  </div>
</template>

<script>
import FormGroupInput from '@/views/components/form/FormGroupInput'

export default {
  name: 'FeedbackFormActionCallback',
  components: {
    FormGroupInput
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true }
  }
}
</script>
