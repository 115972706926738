<template>
  <div>
    <box-section>Event: Registration</box-section>
    <row>
      <column-input>
        <form-group-select name="lists" :model="formData.lists" :options="data.lists" :disabled="!data.lists.length" multiple required/>
      </column-input>
      <column-input :md="2" :xs="6">
        <form-group-check name="openers_only" :model="formData.openers_only"/>
      </column-input>
      <column-input :md="2" :xs="6">
        <form-group-check name="clickers_only" :model="formData.clickers_only"/>
      </column-input>
    </row>
  </div>
</template>

<script>
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'

export default {
  name: 'FeedbackFormEventRegistration',
  components: {
    FormGroupSelect,
    FormGroupCheck
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true }
  },
  computed: {
    openersOnly () { return this.formData.openers_only.value },
    clickersOnly () { return this.formData.clickers_only.value }
  },
  watch: {
    openersOnly (val) { if (!val) this.formData.clickers_only.value = false },
    clickersOnly (val) { if (val) this.formData.openers_only.value = true }
  }
}
</script>
