<template>
  <div>
    <portal to="page-top-title">Edit Feedback #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'feedbacks-single', params: { id: id } }" icon="fa-eye" text>Back to Feedback</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="feedbacks-list" :url="`/feedbacks/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <feedback-form-general :formData="formData" :data="data" v-cloak/>
        <component :is="eventLayoutName" :formData="formData" :data="data" v-cloak/>
        <component :is="actionLayoutName" :formData="formData" :data="data" v-cloak/>
      </template>
    </box-form>
  </div>
</template>

<script>
import Btn from '@/views/components/simple/Btn'
import BoxForm from '@/views/components/data/BoxForm'
import PageData from '@/views/pages/feedbacks/partials/forms/PageData'
import Form from '@/modules/utils/Form'
import FeedbackEvents from '@/configs/feedback-events'
import FeedbackActions from '@/configs/feedback-actions'
import FeedbackFormGeneral from '@/views/pages/feedbacks/partials/forms/General'
import FeedbackFormEventClick from '@/views/pages/feedbacks/partials/forms/EventClick'
import FeedbackFormEventRegistration from '@/views/pages/feedbacks/partials/forms/EventRegistration'
import FeedbackFormActionEmail from '@/views/pages/feedbacks/partials/forms/ActionEmail'
import FeedbackFormActionCallback from '@/views/pages/feedbacks/partials/forms/ActionCallback'

export default {
  name: 'FeedbacksEdit',
  metaInfo () {
    return { title: `Feedback #${this.id} | Edit` }
  },
  components: {
    Btn,
    BoxForm,
    FeedbackFormEventClick,
    FeedbackFormEventRegistration,
    FeedbackFormActionEmail,
    FeedbackFormActionCallback,
    FeedbackFormGeneral
  },
  computed: {
    templateCat () { return this.formData.templates_category.value },
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      showCampaigns: false,
      showLists: false,
      eventLayoutName: '',
      actionLayoutName: '',
      layoutEvents: {
        [FeedbackEvents.CLICK]: FeedbackFormEventClick.name,
        [FeedbackEvents.REGISTRATION]: FeedbackFormEventRegistration.name
      },
      layoutActions: {
        [FeedbackActions.EMAIL]: FeedbackFormActionEmail.name,
        [FeedbackActions.CALLBACK]: FeedbackFormActionCallback.name
      },
      formData: {
        name: Form.types.input(),
        event: Form.types.select(),
        action: Form.types.select(),
        callback_url: Form.types.input(),
        delay: Form.types.input(),
        openers_only: Form.types.boolean(false),
        clickers_only: Form.types.boolean(false),
        active: Form.types.boolean(true),
        lists: Form.types.multiSelect(),
        campaigns: Form.types.multiSelect(),
        api: Form.types.select(),
        sender_categories: Form.types.multiSelect(),
        domains: Form.types.multiSelect(),
        offer: Form.types.select(),
        templates: Form.types.multiSelect(),
        templates_category: Form.types.select()
      },
      data: {
        lists: [],
        campaigns: [],
        events: [],
        actions: [],
        apis: [],
        sender_categories: [],
        domains: [],
        offers: [],
        templateCats: [],
        templates: []
      }
    }
  },
  created () {
    this.loadEntity()

    PageData.setResult(this.data)
    PageData.loadAll().then()
  },
  watch: {
    templateCat (val) { this.templateCatChanged(val) }
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/feedbacks/${this.id}`)
        .then((res) => {
          const { data: { data } } = res
          this.formData.name.value = data.name
          this.formData.action.value = { id: data.action, name: data.action_text }
          this.formData.event.value = { id: data.event, name: data.event_text }
          this.formData.active.value = data.active
          this.formData.openers_only.value = data.settings.openers_only
          this.formData.clickers_only.value = data.settings.clickers_only
          this.formData.callback_url.value = data.settings.callback_url
          this.formData.delay.value = data.settings.delay

          if (data.action === FeedbackActions.EMAIL) {
            if (data.settings.api) this.formData.api.value = { id: data.settings.api.id, name: data.settings.api.name }
            if (data.settings.offer) this.formData.offer.value = { id: data.settings.offer.id, name: data.settings.offer.name }
            const templateCat = data.templates[0].category
            if (templateCat) this.formData.templates_category.value = { id: templateCat.id, name: templateCat.name }
            this.formData.sender_categories.value = this.mapEntity(data.sender_categories)
            this.formData.domains.value = this.mapEntity(data.domains)
            this.formData.templates.value = this.mapEntity(data.templates)
          }

          if (data.event === FeedbackEvents.CLICK) {
            this.formData.campaigns.value = this.mapEntity(data.campaigns)
          } else {
            this.formData.lists.value = this.mapEntity(data.lists)
          }

          this.eventLayoutName = this.layoutEvents[data.event]
          this.actionLayoutName = this.layoutActions[data.action]

          this.loaded = true
        })
    },
    mapEntity (item) {
      return item.map((el) => { return { id: el.id, name: el.name } })
    },
    templateCatChanged (cat) {
      const selectedTemplates = this.formData.templates.value

      this.data.templates = []
      this.formData.templates = Form.types.multiSelect()

      PageData.loadTemplates(cat.id).then(() => {
        this.formData.templates.value = selectedTemplates.length > 0
          ? this.data.templates.filter((template) => selectedTemplates.some((st) => st.id === template.id))
          : []
      })
    }
  }
}
</script>
