<template>
  <div>
    <box-section>Event: Click</box-section>
    <row>
      <column-input>
        <form-group-select name="campaigns" :model="formData.campaigns" :options="data.campaigns" :disabled="!data.campaigns.length" multiple required/>
      </column-input>
    </row>
  </div>
</template>

<script>
import FormGroupSelect from '@/views/components/form/FormGroupSelect'

export default {
  name: 'FeedbackFormEventClick',
  components: {
    FormGroupSelect
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true }
  }
}
</script>
